// Our main CSS
import '../css/app.css'

/**
 * 🦄: Now do your magic.
 */

import LazyLoad from 'vanilla-lazyload'

import './Favorites/index'
import initMobileMenu from './components/MobileMenu'

initMobileMenu()

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 25,
})
window.lazyload = lazyLoad

const BookSlider = document.querySelectorAll<HTMLElement>('[data-js="BookSlider"]')
if (BookSlider && BookSlider !== null) {
  import('./components/BookSlider')
    .then((module) => {
      module.default(BookSlider as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const BookFilter = document.querySelector<HTMLElement>('[data-js="BookFilter"]')
if (BookFilter && BookFilter !== null) {
  import('./BookFilter/index')
    .then((module) => {
      module.default(BookFilter)
    })
    .catch((err) => {
      console.log(err)
    })
}

const BookFavoriteList = document.querySelector<HTMLElement>('[data-js="BookFavoriteList"]')
if (BookFavoriteList && BookFavoriteList !== null) {
  import('./BookFavoriteList')
    .then((module) => {
      module.default(BookFavoriteList)
    })
    .catch((err) => {
      console.log(err)
    })
}

const NewsFilter = document.querySelector<HTMLElement>('[data-js="NewsFilter"]')
if (NewsFilter && NewsFilter !== null) {
  import('./NewsFilter/index')
    .then((module) => {
      module.default(NewsFilter)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AgeSelectorTakeover = document.querySelector<HTMLElement>('[data-js="AgeSelectorTakeover"]')
if (AgeSelectorTakeover && AgeSelectorTakeover !== null) {
  import('./AgeSelectorTakeover')
    .then((module) => {
      module.default(AgeSelectorTakeover)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AgeSelector = document.querySelector<HTMLElement>('[data-js="AgeSelector"]')
if (AgeSelector && AgeSelector !== null) {
  import('./AgeSelector')
    .then((module) => {
      module.default(AgeSelector)
    })
    .catch((err) => {
      console.log(err)
    })
}
let VoteTakeoverModule: any = null
let isModuleLoaded = false
const VoteTakeover = document.querySelector<HTMLElement>('[data-js="VoteTakeover"]')
if (VoteTakeover) {
  const asideHtmlTemplate = VoteTakeover.querySelector('[data-price-content]')
  const asideHtml = asideHtmlTemplate?.innerHTML || ''
  const termsUrl = VoteTakeover.getAttribute('data-terms-url') ?? ''
  document.addEventListener('click', (event) => {
    const target = event.target as HTMLElement
    const voteButton = target.closest('[data-vote-handler]')

    if (voteButton) {
      const bookId = voteButton.getAttribute('data-book-id')
      const bookTitle = voteButton.getAttribute('data-book-title')
      const bookCoverUrl = voteButton.getAttribute('data-book-cover-url')

      if (bookId && bookTitle) {
        if (!isModuleLoaded) {
          import('./VoteTakeover')
            .then((module) => {
              isModuleLoaded = true
              VoteTakeoverModule = module
              VoteTakeoverModule.default(
                VoteTakeover,
                bookId,
                bookTitle,
                bookCoverUrl,
                true,
                asideHtml,
                termsUrl
              )
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          VoteTakeoverModule.default(
            VoteTakeover,
            bookId,
            bookTitle,
            bookCoverUrl,
            true,
            asideHtml,
            termsUrl
          )
        }
      }
    }
  })
}
